import Worksheet from "./Worksheet"

export default function RightSlide (props) {
    const {
        items,
        category,
        showSolutionsSlide,
    } = props;
    return (
        <div className="w-50 h-100 bg-secondary shadow-overlay overflow-y-scroll drop-shadow"
            style={{
                zIndex:10000, 
                minWidth:'1640',
                right:'-50%'
            }}
            ref={showSolutionsSlide}
        >
            {items&&items.map((page, index)=>
                <div key={index} className="px-4 my-3">
                    <Worksheet 
                        src={'vendors-upload/'+page} 
                        className="w-100"
                        category={category}
                    />
                </div>
            )}
        </div>
    )
}
