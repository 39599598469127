import axios from "axios";
import { api } from "./config";

export default class API{

    static async authenticate(username, password) {
        const data = {
            username: username.toLowerCase(),
            password: password
        }
        return axios.post(api+'api/accounts', data)
    }

    static async getAccount(token) {
        return axios.get(api+'api/accounts', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async updateAccounts(token, updateObjects) {
        return axios.put(api+'api/accounts/'+null, updateObjects, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
}