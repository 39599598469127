import React, { useEffect, useState } from "react"
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Globalnavigation from './components/navigation';
import Notification from './components/notification';
import * as routes from './screens/routes'
import ScrollButton from "./components/scrollTopBtn";
import Footer from'./components/footer';
import Preload from "./components/preload";

function App() {
  const dispatch = useDispatch();
  const showPreload = useSelector(state=>state.showPreload)
  const cart = useSelector(state=>state.cart)
  const showNotification = useSelector(state=>state.notification.show)

  const priceTags = [
    {
        title: 'Monthly',
        key: 'priceMonthly'
    }, 
    {
        title: 'Yearly',
        key: 'priceYearly'
    }
  ]
  const navigations = [
    {
      title: 'Home',
      childPath: false,
      witFooter: true
    },
    {
      title: 'About',
      childPath: true,
      witFooter: true
    },
    {
      title: 'EpenApp',
      childPath: true,
      witFooter: false
    },
    {
      title: 'Epenstore',
      childPath: false,
      witFooter: true
    },
    {
      title: 'SingleBook',
      childPath: true,
      witFooter: true
    },
    {
      title: 'Cart',
      childPath: false,
      witFooter: true
    }
  ]
  const [subscribedModel, setSubscribedModel] = useState(priceTags[0].key)
  const updateCart = (method, update) => ({
      'ADD':()=>dispatch({type: 'SET_CART', payload: [
          ...cart,
          {...update,
          subscribedModel: subscribedModel}]}),
      'PUT':()=>dispatch({type: 'SET_CART', payload:
          cart.map(item=> item._id === update._id ?
              {...item, subscribedModel: update.subscribedModel}
              :
              item
          )}),
      'DELETE':()=>dispatch({type: 'SET_CART', payload: 
          cart.filter(item=>item._id!==update._id)})
  }[method])()


  useEffect(() => {
      document.title = "ePenClass | Advocating Technology That Truly Empowers Educators";
  })

  return (
    <Router>
      <div className="App">
        {showPreload&&<Preload/>}
        <Globalnavigation
          navigations={navigations}
        />
        <Routes>
        {navigations.map((navigation, index)=>
          <Route
            key={index}
            path={(index!==0?'/'+navigation.title.toLowerCase():'/')+(navigation.childPath?"/*":"")}
            element={[
              <div key='1' style={{paddingTop: navigation.witFooter?67:0}}>
                {React.createElement(routes[navigation.title],{
                  priceTags: priceTags,
                  subscribedModel: subscribedModel,
                  setSubscribedModel: setSubscribedModel,
                  updateCart: updateCart
                })}
              </div>,
              navigation.witFooter&&
              <Footer key='2'/>,
              navigation.witFooter&&
              <ScrollButton key='3'/>,
            ]}
          />
        )}
        </Routes>
        </div>
        {showNotification&&
          <Notification />
        }
    </Router>
  )

}

export default App;
