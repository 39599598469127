
import { Button } from "react-bootstrap";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import CartItemList from "./cartItemList";
import CheckoutForm from "./checkoutForm";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { stripe_public_token } from "../../API/config";

export default function Cart (props) {
    const {
        priceTags,
        updateCart,
    } = props;

    const stripeInit = loadStripe(stripe_public_token);
    const [checkout, setCheckOut] = useState(false)

    return (
    <Elements stripe={stripeInit}>
        <div 
            className="row vh-100 mx-auto overflow-hidden"
        >
            <div 
                className="col-xl-7 col-md-12 border px-3 ePen-theme-secondary"
                style={{height: checkout?0:'100%', paddingTop: 33, transition:'0.3s ease'}}
            >
                <CartItemList
                    priceTags={priceTags}
                    updateCart={updateCart}
                />
            </div>
            <div 
                className="col-xl-5 col-md-12 h-100"
                style={{paddingTop: 33}}
            >
                <CheckoutForm/>
            </div>
                {isMobile&&<div className="position-fixed w-100 px-3 text-end"
                style={{bottom:'20px'}}
            >
                <Button
                    className="shadow mx-4"
                    variant="secondary"
                    onClick={()=>
                        setCheckOut(!checkout)
                    }
                >
                    {checkout?'View cart':'Payment info'}
                </Button>
            </div>}
        </div>
    </Elements>
    )
}