import { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";

import LC, {LiterallyCanvasReactComponent} from "literallycanvas";
import { assetsSrc, websocket } from "../../../../API/config"

import UploadsAPI from "../../../../API/uploads";
import DrawingAPI from "../../../../API/drawing";
import RightSlide from "../../../components/rightSlide";


export default function EditTool (props) {
    const { 
        socket,
        setSocket,
        allowSolutions,
        setAllowSolutions,
        showSolutionsSlide,
    } = props

    const [imgs, setImgs] = useState([]);
    const [img, setImg] = useState([]);
    const [backgroundWorksheet, generateBackgroundWorksheet] = useState([]);
    const [drawingId, setDrawingId] = useState(null);
    const [drawingSnapshot, setDrawingSnapshot] = useState(null)
    const [showBanner, setShowBanner] = useState(false);
    const dispatch = useDispatch();

    const token = useSelector(state=>state.token)
    const myInfo = useSelector(state=>state.myInfo)
    const tgtUser = useSelector(state=>state.tgtUser)
    const currentResource = useSelector(state=>state.currentResource)
    const bookmark = useSelector(state=>state.bookmark)

    let nIntervId;
    const save = async (snapshot) => {
        clearInterval(nIntervId);
        nIntervId = setInterval(async()=>{
            await DrawingAPI.updateDrawing(token, currentResource._id, tgtUser._id??myInfo._id, drawingId, snapshot)
            .catch((err)=>console.log(err))
            await clearInterval(nIntervId);
            nIntervId = null
        }
        , 5000)
    }
    const LcContainer = () => {
    return(
        <LiterallyCanvasReactComponent
            onInit={canvasInit}
            imageURLPrefix="/assets"
            defaultStrokeWidth={2}
            backgroundShapes={backgroundWorksheet}
            primaryColor={
                tgtUser._id?"#c22929":"#006eff"
            }
            tools={[
                LC.tools.Pencil,
                LC.tools.Eraser,
                // LC.tools.Line,
                // LC.tools.Rectangle,
                LC.tools.Text,
                LC.tools.Pan,
    
            ]}
        />
    )}

    const canvasInit = async lc => {
        drawingSnapshot&&lc.loadSnapshot(drawingSnapshot)

        //Overrriding default LC tools graphics with FA
        var tools = document.getElementsByClassName("toolbar-button thin-button")
        for ( var i=0; i<tools.length; i++) {
            var faIconChild = document.createElement('i')
            if([...tools][i].title==="Text"){
                faIconChild.className = "fa-solid fa-keyboard"
            }else if ([...tools][i].title==="Pan") {
                faIconChild.className = "fa-solid fa-up-down-left-right"
            }else if ([...tools][i].title==="Zoom out") {
                faIconChild.className = "fa-solid fa-magnifying-glass-minus"
            }else if ([...tools][i].title==="Zoom in") {
                faIconChild.className = "fa-solid fa-magnifying-glass-plus"
            }else{
                faIconChild.className = "fa-solid fa-"+ [...tools][i].title.toLowerCase()
            }
            tools[i].style.backgroundImage = null
            tools[i].appendChild(faIconChild)
        }

        const sendDrawingtoWebsocket = async (data) => {
            data&&socket.readyState!==0&&socket.send(JSON.stringify(data))
            save(lc.getSnapshot(['shapes']))
        }
        lc.on('drawStart', ()=>{
            //save after 5 secconds if no further input
            // listening to drawing change event
            var unsubscribe = lc.on('drawingChange', ()=>{
                sendDrawingtoWebsocket({
                    className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className,
                    color: tgtUser._id?"#c22929":"#006eff",
                    points:LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointCoordinatePairs, 
                })
            })
            lc.on('drawEnd', ()=>unsubscribe())
        })
        lc.on('toolChange', ()=>{
            Object.getPrototypeOf(lc.tool).name === "Eraser"?
                lc.tool.strokeWidth = 25
                :
                lc.tool.strokeWidth = 2
        })
        lc.on('undo', ()=>{
            sendDrawingtoWebsocket({
                className:'undo',
            })
        })
        //handle undo and redo temporarily, this needs to be update later
        lc.on('redo', ()=>{
            sendDrawingtoWebsocket({
                className:LC.util.last(lc.getSnapshot(['shapes']).shapes).className,
                color: LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointColor,
                points:LC.util.last(lc.getSnapshot(['shapes']).shapes).data.pointCoordinatePairs, 
            })
        })

        socket.onmessage = async (e) => {
            let data = JSON.parse(e.data)
            // console.log(data)
            // recieved broadcast data
            data.className==='LinePath'||data.className==='ErasedLinePath'?
            lc.saveShape(LC.createShape(data.className, {
                points: data.points.map(
                    points=>LC.createShape('Point', {x:points[0], y:points[1], size:data.className==='LinePath'?2:25, color:data.color})
                )
            }))
            :
            data.className==='undo'&&lc.loadSnapshot({shapes: lc.getSnapshot(['shapes']).shapes.slice(0, -1)})
        }
    };
    useEffect(()=>{
        dispatch({type:'SHOW_PRELOAD', payload: true});
        
        if(currentResource._id) {
            setSocket(new WebSocket(websocket+'?id='+currentResource._id));
            DrawingAPI.loadDrawing(token, currentResource._id, tgtUser._id??myInfo._id)
            .then(response=>{
                setDrawingSnapshot(response.data.DrawingSnapshot)
                dispatch({type:'SHOW_PRELOAD', payload: false});
    
                !response.data.DrawingSnapshot?
                DrawingAPI.saveDrawing(token, currentResource._id, tgtUser._id??myInfo._id, [])
                .then(response=>
                    setDrawingId(response.data)
                )
                :
                setDrawingId(response.data._id)
            }).catch(err=>{
                console.log(err)
                dispatch({type:'SHOW_PRELOAD', payload: false});
            })
        }
    },[currentResource, tgtUser])
        useEffect(()=>{
            dispatch({type:'SHOW_PRELOAD', payload: true});

            if(currentResource.Type==='Workbook'){
                setAllowSolutions(currentResource.Solutions.length>0?currentResource.Solutions[0]._id:false)
                setShowBanner(currentResource.Category)
                setImgs(currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets)
                currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Worksheets.map(()=>setImg(item=>[...item, new Image]))

            }else if(currentResource.Type==='assignment'){
                setAllowSolutions(currentResource.Solution!=='false'?currentResource.Solution:false)
                setShowBanner(currentResource.Category);
                setImgs(currentResource.Worksheets)
                currentResource.Worksheets.map(()=>{
                    setImg(item=>[...item, new Image])
                })
            }
            currentResource.type==='uploads'&&UploadsAPI.uploadsShow(token, currentResource.id)
            .then(async response=>{
                await dispatch({type:'SHOW_PRELOAD', payload: true});
                await setImgs(response.data.Worksheets)
                await response.data.Worksheets.map(async ()=>{
                    await setImg(item=>[...item, new Image])
                    await dispatch({type:'SHOW_PRELOAD', payload: false});
                })
            })
            dispatch({type:'SHOW_PRELOAD', payload: false});
        },[bookmark, currentResource, tgtUser])
        useEffect(()=>{
            generateBackgroundWorksheet(()=>{
                var staticBanner = new Image;
                    staticBanner.src = showBanner==="Foundation"&&"/assets/static-banner.jpeg";
                const backgroundWorksheets = [];
                    imgs.sort().forEach((item, index) => {
                        img[index].src = assetsSrc+(currentResource.Type!=='uploads'?'vendors-upload/':'users-upload/')+(currentResource.Type==='assignment'?currentResource.Origin:currentResource._id)+'/'+item;
                        img[index].height = 2750;
                        img[index].width = 2126;
                        backgroundWorksheets.push(
                        LC.createShape('Image', {
                            x: 0, 
                            y: index*750, 
                            image: img[index],
                            scale: 0.26
                        }),
                        LC.createShape('Image', {
                            x: 30, 
                            y: index*750+40, 
                            image: staticBanner,
                            scale: 0.38
                        }),

                        )
                    })
                
                return backgroundWorksheets;
            })
        },[img, showBanner, currentResource, tgtUser])
    return (
        <div className="h-100 w-100">
            <div
                className="h-100 overflow-hidden d-flex justify-content-end"
            >
                <RightSlide
                    showSolutionsSlide={showSolutionsSlide}
                    items={imgs.map(item=>allowSolutions+'/'+item)}
                    category={showBanner}
                />
                {backgroundWorksheet.length>0&&
                    <LcContainer/>
                }
            </div>
        </div>
    )
}
