
import { Accordion, Card } from 'react-bootstrap';
import Row from "./row";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function RowContainer (props) {
    const {
        target,
        tableEntryTemplates,
        className,
        childrenRowKey,
        onLoad,
        isSubmitted,
        setIsSubmitted,
        extractFilter
    } = props;
    const [entries, setEntries] = useState([]);
    const globalFilter = useSelector(state=>state.filters)
    const currentResource = useSelector(state=>state.currentResource);
    const srcGroup = useSelector(state=>state.srcGroup);
    const filters = extractFilter(globalFilter);
    useEffect(()=>{
        onLoad(setEntries, filters);
    },[srcGroup, filters])
    useEffect(()=>{
        if(isSubmitted){
            onLoad(setEntries, filters);
            setIsSubmitted(false)
        }
    },[isSubmitted])

    const OuLoop = ({array}) => {
    return(
        array.map((item, cindex)=>
        <Card
            key={cindex}
            className='border-0 bg-none'
        >
            <Card.Header className="">
                <Row
                    item={item} 
                    eventKey={childrenRowKey&&item[childrenRowKey].length>0?cindex:"null"}
                    target={target}
                    tableEntryTemplates={tableEntryTemplates}
                    onRowUpdate={()=>onLoad(setEntries, filters)}
                />
            </Card.Header>
            {childrenRowKey&&item[childrenRowKey].length>0&&
            <Accordion.Collapse 
                eventKey={cindex} 
                className='w-100' 
                style={{background: "rgba(0,0,0,0.1)"}}
            >
                <Accordion className="text-dark" defaultActiveKey={cindex}>
                    <OuLoop array={item[childrenRowKey]}/>
                </Accordion>
            </Accordion.Collapse>
            }
        </Card>
        )
    )
    }
    return (
        <Accordion className={className+ " text-dark"} defaultActiveKey={null}>
            {entries.length>0?
            <OuLoop
                array={entries
                    //currently hard-code filter by assignment. This condiftion only track when the currentResource object is available
                    .filter(entry=>entry&&currentResource.Users?currentResource.Users.some(x=>x===entry._id):entry)
                    .filter(items=>filters.input!==''?
                        tableEntryTemplates.filter(cols=>cols.key&&cols.actions.length<=1&&!cols.showCount).some(col=>
                            items[col.key]&&items[col.key].toLowerCase().indexOf(filters.input)>=0
                        )
                        :
                        items
                    )}
            />
            :
            <div className='text-start m-3'>
                <p>No entry found</p>
            </div>
            }
        </Accordion>
)}    
