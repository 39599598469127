
export const Category = [
    {name:"All Catergories", value: '0'},
    {name:"School Workbook", value:"Concept"},
    {name:"Supplemental Workoooks", value:"Foundation"},

];

export const Subject = [
    {name:"All Subjects", value:'0'},
    {name:"Math", value:"Math"},
    {name:"English", value:"English"},
    {name:"Science", value:"Science"},
    {name:"Chemistry", value:"Chemistry"},
    {name:"Physics", value:"Physics"},
    {name:"Others", value:"Others"},
];