import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom";
import PaymentsAPI from "../../API/payments";

export default function CheckoutForm () {
const elements = useElements();
const stripe = useStripe();
const [cards, setCards] = useState([]);

const dispatch = useDispatch();
const navigate = useNavigate();
const token = useSelector(state=>state.token);
const myInfo = useSelector(state=>state.myInfo);
const cart = useSelector(state=>state.cart);
const tempBill = [
    {
        title: "monthly",
        key:"priceMonthly"
    },
    {
        title: "yearly",
        key:"priceYearly"
    },
    {
        title: "now",
        key:"total"
    }
]

const proceedToCheckOut = async (card) => {
    const data = {
        tokenId: card.token?card.token.id:card,
        subscriptions:{
            month:cart.filter(item=>item.subscribedModel==="priceMonthly"),
            year:cart.filter(item=>item.subscribedModel==="priceYearly")
        }
    }
    console.log(card)
    console.log(data)
    await dispatch({type:'SHOW_PRELOAD', payload: true});
    //add stripe and billing objetcs. But the code is settle for individual users
      await PaymentsAPI.storeSubscripitons(token, data)
      .then(async response=>{
        await dispatch({type:"SET_MY_INFO", payload: 
          {...myInfo, stripeId: response.data.id}
        })
        await dispatch({type:'SHOW_PRELOAD', payload: false});
        await window.location.replace('/epenApp/resource');
        await dispatch({ type: 'SET_NOTIFICATION', payload: {
            show: true,
            code: 200,
            message: "Payment success."
        }})
      })
      .catch(()=>dispatch({ type: 'SET_NOTIFICATION', payload: {
            show: true,
            code: 500,
            message: "Payment failed."
        }}))
      await dispatch({type:'SHOW_PRELOAD', payload: false});
  }

useEffect(()=>{
    myInfo.stripeId&&PaymentsAPI.getSource(token)
    .then(response=>setCards(response.data))
    .catch(err=>console.log(err))
},[myInfo])

return (
<div 
    className="bg-white rounded rounded-4 shadow my-2 pb-5 mx-auto overflow-hidden"
    style={{width: '450px'}}
>
    <div className="bg-secondary text-white mb-5 p-3">
        {tempBill.map((due, index)=>cart.filter(item=>due.key!=='total'?item.subscribedModel===due.key:item).length>0?
        <p key={index} className={"secondary-font d-flex justify-content-between m-0 "+(due.key==='total'&&'mt-3 h5')}>
            <span>Due {due.title}: </span>
            <b className='prime-font'>${
                cart
                    .map(item=>
                        due.key==='total'?
                        Number(item[item.subscribedModel])
                        :
                        item.subscribedModel===due.key&&Number(item[item.subscribedModel]))
                    .reduce((a, b)=>a+b)
                    .toFixed(2)
            }</b>
        </p>
        :
        <p key={index} ></p>
        )}
    </div>
    {myInfo?
    <div>
        <div className="px-4">
            <Form>
                {cards.data&&cards.data.map((item, index)=>
                <div
                    key={index}
                >
                    <Form.Check
                        className="text-start"
                        checked={index===0}
                        type={'radio'}
                        label={`**** **** **** ${item.last4} - ${item.exp_month}/${item.exp_year.toString().slice(-2)}`}
                    />
                </div>
                )}
                <div className="text-start">
                    {cards.data&&<Form.Check
                        type='radio'
                        label={'add a new card'}
                    />}
                </div>
                {(!cards.data)&&<CardElement
                    className="form-control login-input rounded-0 text"
                    options={{
                        style:{
                            base: {
                                fontFamily:"'Raleway', sans-serif",
                                fontSize: '16px',
                            }
                        }
                    }}
                />}
            </Form>
            <br/>
            <Button 
                className="w-75 mt-2" 
                onClick={async()=>{
                    proceedToCheckOut(
                        await stripe.createToken(elements.getElement("card"))
                )}}
                disabled={cart.length===0}
            >
                {cart.length===0?'Cart is empty':'Proceed to checkout'}
            </Button>
        </div>
    </div>
     :
    <div>
    <div>
        <p className='m-0'><Button 
            className="my-2 me-2 bg-304d73 border-0"
            onClick={()=>{
                navigate('/epenApp', {state:{back:"/cart"}})
                dispatch({type:"SET_SCREEN", payload:'login'})
            }}
        >Sign in</Button>or<Button
            variant='outline-secondary'
            className="mx-2"
            onClick={()=>{
                navigate('/epenApp', {state:{back:"/cart"}})
                dispatch({type:"SET_SCREEN", payload:'register'})
            }}
        >Sign up</Button> to checkout</p>
    </div>
    </div>
    }
</div>
)
}