import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

export default function ExpandToggle (props) {
    const {
        hidden,
        expanded,
        setExpand
    } = props;

    return (
        <a
            className={'col-auto m-0 p-0 '+(!isNaN(hidden)&&'pointer')}
            variant="outline-dark"
            style={{opacity: isNaN(hidden)?'0':'1'}}
            onClick={()=>isNaN(hidden)?'':setExpand()}
        >
            <FontAwesomeIcon
                className='text-secondary'
                icon={expanded&&!isNaN(hidden)? faMinusSquare:faPlusSquare }
            />
        </a>
)}