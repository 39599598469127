import axios from "axios";
import { api } from "./config";

export default class API{

    static async userIndex(token, groupId, filters) {
        return axios.get(api+'api/users?_id='+groupId+
            '&role='+filters.role, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async userShow(token, userId) {
        return axios.get(api+'api/users/'+userId, {
            'headers':{
                'Authorization': 'Bearer '+token
            }
        })
    }
    
    static async addUser(token, data) {
        return axios.post(api+'api/users', data, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async usersUpdate(token, userId, data) {
        return axios.put(api+'api/users/'+userId, data, {
            'headers' : {
                'Authorization': 'Bearer '+token
            }
        })
    }
    
    static async removeUser(token, userId) {
        return axios.delete(api+'api/users/'+userId, {
            'headers':{
                'Authorization': 'Bearer '+token
            }
        })
    }
}