import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import { useDispatch } from "react-redux"


export default function AssignButton(props) {
    const {
        active,
        prevScreen
    } = props
    const dispatch = useDispatch();
    return(
    <Link
        onClick={()=>dispatch({ type: 'SET_SCREEN', payload: prevScreen+'/resourceIndexBySection'})}
        to={'/epenApp/resourceIndexBySection'}
    >
        <Button
            variant="secondary"
            disabled={active}
            className="rounded-0 rounded-start"
        >
            Assign
        </Button>
    </Link>
    )
}