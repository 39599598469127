import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {Button, ButtonGroup, ToggleButton, Carousel } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Worksheet from '../../epenApp/components/Worksheet';
import './singleBook.css';
import { isMobile, isTablet } from 'react-device-detect';


function SingleBook(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        priceTags,
        updateCart,
        subscribedModel, 
        setSubscribedModel
    } = props
    const [display, setDisplay] = useState(false);
    const currentResource = useSelector(state=>state.currentResource);

    const cart = useSelector(state=>state.cart);
    const [] = useState(priceTags[0].key)
    useEffect(()=>{
        currentResource&&cart.filter(item=>item._id === currentResource._id)[0]&&
        setSubscribedModel(cart.filter(item=>item._id === currentResource._id)[0].subscribedModel)
    },[currentResource])

    return (
        currentResource&&<div style={{paddingTop:30, height: isMobile&&!isTablet?'auto':'70vh'}}>
        <div className='w-100 row'>
            <div 
                className='w-100 h-100 shadow-overlay fixed-top row align-items-center justify-content-center m-0' 
                style={{zIndex: display?2000 : -1, opacity:display? 1:0}} onClick={()=>setDisplay(false)}
            >
                <div className='col-lg-6 col-md-11 mx-auto'>
                    <Worksheet
                        src = {display}
                        category={display&&display.indexOf('cover')<0&&currentResource.Category}
                        style={{maxHeight: '75vh'}}
                        className='w-100'                               
                    />
                </div>
            </div>
            <div className='col-12 text-start mb-3 mt-3 mt-xl-0'>
                <Link to='/epenstore'>
                    <Button
                        variant='outline-dark'
                    >
                        Back to store
                    </Button>
                </Link>
            </div>
            <div id='singleBook' className='col-auto mx-auto mb-5 mb-xl-0'>
                <Carousel
                    controls={false}
                    className='col-11 mx-auto rounded-3'
                    style={{
                        height: 330, 
                        width: 255,
                    }}
                >
                {   
                currentResource.Previews&&
                [currentResource.Cover, ...currentResource.Previews].map((page, index) =>
                    <Carousel.Item
                        key={index}
                        onClick={()=>setDisplay('vendors-upload/'+currentResource._id+'/'+page, index!==0)}
                    >
                        <Worksheet
                            src = {'vendors-upload/'+currentResource._id+'/'+page}
                            category={index!==0&&currentResource.Category}
                        />
                    </Carousel.Item>
                    )
                }
                </Carousel>
            </div>
            <div className='col row'>
                <div className='bg-304d73 rounded-3 col'>
                    <div className='text-white text-start p-3'>
                        <p className='m-0'><b>Title:</b> {currentResource.Title}</p>
                        <p className='m-0'><b>Grade:</b> {currentResource.Level}</p>
                        <p className='m-0'><b>Author:</b> DataPi Learning Inc.</p>
                        <p className='m-0'><b>Publisher:</b> DataPi Learning Inc.</p>
                        <p className='m-0'><b>Solution:</b> {
                            currentResource.Solutions&&currentResource.Solutions.length>0?'Included':'Not Available'
                        }</p>
                        <br/>
                        <p className='m-0'><b>Description:</b> Purchase this resource to unlock more feature within the EpenApp</p>
                    </div>
                </div>
                <div className='col-xl-4 my-4 text-start ms-2 ps-2 d-flex align-items-start flex-column'>
                    <div className='w-100 text-center'>
                        <span className='me-2 secondary-font'>Subscribe</span>
                        <ButtonGroup>
                            {priceTags.map((tag, index) => (
                            <ToggleButton
                                key={index}
                                type="radio"
                                variant={tag.key === subscribedModel ? 'secondary' : 'outline-secondary'}
                                checked={tag.key === subscribedModel}
                                onClick={async() =>{ 
                                    await setSubscribedModel(tag.key)
                                    await updateCart('PUT', {
                                        ...currentResource,
                                        subscribedModel: tag.key
                                    })
                                }}
                            >
                                {tag.title}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                        <h1 className='my-3'>CAD$ {currentResource[subscribedModel]}</h1>
                    </div>
                    <div className='w-100 text-center mt-auto'>
                        {!cart.some(x=>x._id===currentResource._id)?
                        <Button 
                            className='bg-304d73 border-0'
                            disabled={location.state.IsSubscribed}
                            onClick={()=>updateCart('ADD', currentResource)}
                        >{location.state.IsSubscribed?"Subscribed":"Add to cart"}</Button>
                        :
                        <Button 
                            className='bg-secondary border-0'
                            onClick={()=>updateCart('DELETE', currentResource)}
                        >
                            Remove from cart
                        </Button>    
                        }
                        <Button 
                            variant='outline-secondary ms-2'
                            onClick={async ()=>{
                                navigate('/cart')
                            }}
                        >View cart</Button>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default SingleBook