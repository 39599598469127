import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export default function TOCAddEntry (props) {
const { 
    cindex, 
    isChapter,
    updatePreSubmitTOCdata
} = props

const currentResource = useSelector(state=>state.currentResource);

return (
    <Button
        variant="outline-secondary"
        className="me-2"
        onClick={async ()=>{
            const prevState = currentResource;
            if(isChapter){
                await updatePreSubmitTOCdata({
                    ...currentResource,
                    Chapters: [
                        ...currentResource.Chapters,
                        {
                            Label:"Chapter "+(Number(currentResource.Chapters.length)+1),
                            Navigations: []
                        }
                    ]
                })
            }else{
                prevState.Chapters[cindex].Navigations = await
                currentResource.Chapters[cindex].Navigations?
                currentResource.Chapters[cindex].Navigations.concat({
                    Label: (Number(cindex)+1)+"."
                    +(Number(prevState.Chapters[cindex].Navigations.length)+1)+"-title",
                    Worksheets: []
                })
                :
                currentResource.Chapters[cindex].Navigations = 
                [{
                    Label: (Number(cindex)+1)+"."
                    +"1-title",
                    Worksheets: []  
                }]

                await updatePreSubmitTOCdata({
                    ...prevState,
                })
            }
        }}
    >
        <FontAwesomeIcon icon={faPlus}/> {"Add "+(isChapter?"chapter":"section")}
    </Button>
    )
}