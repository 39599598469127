import { useSelector } from "react-redux";
import { Image, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faStore } from "@fortawesome/free-solid-svg-icons";
import { assetsSrc } from "../../API/config";

export default function CartItemList (props) {
    const {
        priceTags,
        updateCart,
    } = props;
    const cart = useSelector(state=>state.cart);

    return (
    cart.length>0?
    <div className="h-100 overflow-auto">
        {cart.map((asset, index)=>
            <div
                className="p-0 my-3 mx-4 bg-white row shadow rounded rounded-4 overflow-hidden position-relative"
                key={index}
            >
                <div className="col-auto p-0">
                    <Image 
                        src={assetsSrc+'vendors-upload/'+asset._id+'/'+asset.Cover} 
                        style={{
                            height: 220, 
                            width: 170,
                        }}
                    />
                </div>
                <div className="col text-start p-3 d-flex flex-column">
                    <div className="d-flex justify-content-between">
                        <div className="row m-0">
                            <h5>{asset.Title}</h5>
                            <p className="m-0"><span>Subject: </span>{asset.Subject}</p>
                            <p className="m-0"><span>Grade: </span>{asset.Level}</p>
                        </div>
                        <div>
                        <Button
                            variant='outline-secondary'
                            className="d-flex border-0"
                            onClick={()=>updateCart('DELETE', asset)}
                        >
                            <FontAwesomeIcon
                                className="pointer"
                                icon={faTimes}
                            />
                        </Button>
                        </div>
                    </div>
                    <div 
                        className="mt-auto d-flex justify-content-between"
                    >
                        <div className="d-flex align-items-center">
                        <p className="m-0 me-2">Subcsription:</p>
                        <ButtonGroup>
                            {priceTags.map((tag, index) => (
                            <ToggleButton
                                key={index}
                                type="radio"
                                variant={tag.key === asset.subscribedModel ? 'secondary' : 'outline-secondary'}
                                checked={tag.key === asset.subscribedModel}
                                onClick={async() =>{ 
                                    await updateCart('PUT', {
                                        ...asset,
                                        subscribedModel: tag.key
                                    })
                                }}
                            >
                                {tag.title}
                            </ToggleButton>
                            ))}
                        </ButtonGroup>
                        </div>
                        <div>
                            <h5>$ {asset[asset.subscribedModel]}</h5>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    </div>
    :
    <div 
        className="row align-items-start h-100 px-5"
    >
        <div className="row justify-content-center px-5">
            <p>Checkout resources that was built by the top minds</p>
            <Link 
                className="bg-secondary text-white py-4 pointer shadow rounded rounded-4"
                to='/epenstore'
            >
                <FontAwesomeIcon
                    style={{fontSize:'36pt'}}
                    className="mb-3"
                    icon={faStore}
                /><br/>
                <p className="mb-0">Browse all resources</p>
            </Link>
        </div>
    </div>
    )
}