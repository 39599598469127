import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadResources from "../../epenApp/screen/Resources/loadResources";
import { lazyLoad } from "../../epenApp/components/Table/lazyLoad";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import InventoryAPI from "../../API/inventory";
import './style.css';
import Searchbar from "../../epenApp/components/SubNav/searchbar";

function Epenstore(props) {
    const {
        subscribedModel,
        setSubscribedModel,
        priceTags,
        updateCart
    } = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pageLoad, setPageLoad] = useState(1);
    const [prevInnerHeight, setPrevInnerHeight] = useState(0);
    const [resources, setResources] = useState([])
    const [groupResources, setGroupResources] = useState([])
    const [allPublicResources, setAllPublicResources] = useState([])
    const lazyloadContainer = useRef()
    const filter = useSelector(state=>state.filters).filter(item=>item.name==='resources')[0].value
    const token = useSelector(state=>state.token);
    const srcGroup = useSelector(state=>state.srcGroup);
    const cart = useSelector(state=>state.cart);

    useEffect(()=>{
        if(token){
            InventoryAPI.inventoryIndex(token, filter, pageLoad)
            .then(response=>
                pageLoad===1?
                    setResources(response.data)
                    :
                    setResources([...resources, ...response.data]))
            InventoryAPI.groupInventoryIndex(token, srcGroup._id, filter, pageLoad)
            .then(response=>
                pageLoad===1?
                    setGroupResources(response.data)
                    :
                    setGroupResources([...groupResources,...response.data])
            )
        }
    },[token, filter, pageLoad])
    useEffect(()=>{
        dispatch({ type: 'RESET_RESOURCE'})
        dispatch({ type: 'SET_SCREEN', payload:'EpenStore'})
    },[])
    return( 
    <div
        className="mx-0 px-3 overflow-y-scroll vh-100"
        style={{paddingTop: 33}}
        onScroll={(e)=>{
            lazyLoad(e, setPageLoad, prevInnerHeight)&&setPrevInnerHeight(e.target.scrollHeight)
        }}
    >
        <div className="ms-auto pt-xl-0 pt-2 row align-items-center justify-content-end">
            <div className="col-auto d-flex align-items-center my-2">
                <p className="m-0 me-2">Subscribed</p>
                <ButtonGroup>
                    {priceTags.map((tag, index) => (
                    <ToggleButton
                        key={index}
                        type="radio"
                        variant={tag.key === subscribedModel ? 'secondary' : 'outline-secondary'}
                        checked={tag.key === subscribedModel}
                        onClick={async() =>{ 
                            await setSubscribedModel(tag.key)
                        }}
                    >
                        {tag.title}
                    </ToggleButton>
                    ))}
                </ButtonGroup>
            </div>
            <Searchbar
                type={'resources'}
                className='col-md-4'
            />
        </div>
        <LoadResources
            lazyloadContainer={lazyloadContainer}
            type={'inventory'}
            pageLoad={pageLoad}
            showPricing={subscribedModel}
            resources={allPublicResources}
            groupResources={[]}
            setResources={setAllPublicResources}
            
            resourcesIndex={InventoryAPI.publicInventoryIndex}
            actions = {[
                {
                name:'View book',
                verb:'SHOW',
                execute: async (data)=>{
                    await navigate('/SingleBook', {
                        state: {IsSubscribed:[...resources, ...groupResources].some((item)=>item._id===data._id)},
                    })
                    return await InventoryAPI.publicInventoryShow(data._id)
                    }
                },
                {
                name:(data)=>{
                    return [...resources, ...groupResources].some((item)=>item._id===data._id)?
                        'Subscribed'
                        :
                        cart.filter(item=>item._id===data._id).length>0?'Remove from Cart':'Add to cart'
                },
                disabled:(data)=>[...resources, ...groupResources].some((item)=>item._id===data._id),
                verb:'CUSTOM',
                execute: async (data)=>{
                    await dispatch({type:'SET_CURRENT_RESOURCE', payload: data})
                    await cart.filter(item=>item._id===data._id).length<=0?
                    updateCart('ADD', data)
                    :
                    updateCart('DELETE', data)
                }
                }
            ]}
        />
    </div>
    )
}

export default Epenstore