import { Dropdown } from "react-bootstrap";
import { Category, Subject } from "./resroucesFilterCat"


export default function ResourcesFilter (props) {
    const {
        filters,
        setFilters
    } = props
    const Grades = [];
        for(let i = 1 ; i < 13; i++) {
            Grades.push(i)
        }
    
    return (
    <>
    <Dropdown className='p-0 mx-2'>
        <Dropdown.Toggle variant='outline-secondary' size="sm">
            {filters&&Category.filter(x=>x.value===filters.category)[0].name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {filters&&Category.map((item, index)=>
                <Dropdown.Item 
                    key={index} 
                    className='' 
                    onClick={()=>
                    setFilters('resources', {
                        category: item.value,
                        subject: filters.subject,
                        level: filters.level,
                        input: filters.input
                    })}
                >
                    {item.name}
                </Dropdown.Item>
            )}
        </Dropdown.Menu>
        </Dropdown>

        <Dropdown className='p-0 mx-2'>
        <Dropdown.Toggle variant='outline-secondary' size="sm" className='mx-2'>
            {filters&&Subject.filter(x=>x.value===filters.subject)[0].name}
        </Dropdown.Toggle>

        <Dropdown.Menu className=''>
            {filters&&Subject.map((item, index)=>
                 <Dropdown.Item key={index} className='' onClick={()=>
                    setFilters('resources', {
                        category: filters.category,
                        subject: item.value,
                        level: filters.level,
                        input: filters.input
                    })}
                >{item.name}</Dropdown.Item>
            )}
        </Dropdown.Menu>
        </Dropdown>
        <Dropdown className='p-0 ms-2'>
        <Dropdown.Toggle variant='outline-secondary' size="sm">
            {filters.level==='0'&&"All Grades"||
            'Grade '+filters.level ||
            filters.level==='13'&&"Others Grade"}
        </Dropdown.Toggle>
        <Dropdown.Menu className=''>
            <Dropdown.Item className='' key={0} href="" onClick={()=>
                setFilters('resources', {
                        category: filters.category,
                        subject: filters.subject,
                        level: '0',
                        input: filters.input
                })}>All Grade</Dropdown.Item>
            {filters&&Grades.map((item, index)=>
            <Dropdown.Item className='' key={index} href="" onClick={()=>
                setFilters('resources', {
                    category: filters.category,
                    subject: filters.subject,
                    level: item.toString(),
                    input: filters.input
                })}>Grade {item}</Dropdown.Item>)}
            <Dropdown.Item className='' key={13} href="" onClick={()=>
                setFilters('resources', {
                        category: filters.category,
                        subject: filters.subject,
                        level: '13',
                        input: filters.input
                })}>Other</Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        </>
    )
}