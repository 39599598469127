import { Link, useLocation, useNavigate } from 'react-router-dom';
import UserDetails from '../Users/userDetails'
import { useDispatch, useSelector } from 'react-redux';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useEffect } from 'react';

function Register() {
    const dispatch = useDispatch();
    const allowSubmit = useSelector(state=>state.allowSubmit)
    const tgtUser = useSelector(state=>state.tgtUser)
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch({type:'SET_TGT_USER', payload: {
            ...tgtUser,
            groups: [{
                ...tgtUser.groups[0],
                _id: "1",
                role: "User"
            }]
        }})
    },[])
    return(
    <div className='bg-white h-100 d-flex align-items-center'>
        <div className='mx-auto'>
            <UserDetails/>
            <FloatingLabel
                label='Invitation code (optional)'
                className="secondary-font text-secondary my-3 p-2 rounded"
                style={{background:"rgba(0,0,0,0.1)"}}
            >
                <Form.Control
                    type="text"
                    placeholder='invitation code'
                    className="border-0 border-bottom rounded-0 bg-none"
                    onChange={(e)=>dispatch({type:'SET_TGT_USER', payload: {
                        ...tgtUser,
                        groups: [{
                            ...tgtUser.groups[0],
                            _id: e.target.value&& e.target.value!==0? e.target.value:"1",
                            role: "User"
                        }]
                    }})}
                />
            </FloatingLabel>
        <div className="row my-2">
            <Button 
                className="bg-304d73 border-0"
                disabled={!allowSubmit}
                onClick={()=>{
                    navigate(location.state.history, {replace: true})
                }}
            >Sign up</Button>
        </div>
            <p>Already have an account? 
                <Link
                    className='ms-2'
                    onClick={()=>dispatch({type:"SET_SCREEN", payload:'login'})}
                >Sign In</Link>
            </p>
        </div>
    </div>
    );
}

export default Register