import axios from "axios";
import { api } from "./config";

export default class API{

    static async postAssignment(token, data) {

        return axios.post(api+'api/assignment', data,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async assignmentsIndex(token, groupId, filterUpdate, pagenumber, userId) {
        return axios.get(api+'api/assignment?page='+pagenumber+
            '&category='+filterUpdate.category+
            '&subject='+filterUpdate.subject+
            '&level='+filterUpdate.level+
            '&groupId='+groupId+
            (userId?'&userId='+userId:'')
        ,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async assignmentsShow(token, id) {
        
        return axios.get(api+'api/assignment/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async assignmentRemove(token, id) {

        return axios.delete(api+'api/assignment/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
}