import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";

export default function Notification () {

    let notification = useSelector(state=>state.notification)
    let variant;
        switch(parseInt(notification.code)) {
            case 200:
                variant = "success text-white"
              break;
            case 500:
                variant = "warning text-dark"
              break;
            case 300:
                variant = "light text-dark"
                break;
            case 404:
            case 400:
            case 401:
            case 204:
                variant = "danger text-white"
                break;
            default:
                variant = "white"
          }
    const dispatch = useDispatch();
    const dismiss = () => {
        dispatch({ type: 'SET_NOTIFICATION', payload: {
            show: false,
            code: '',
            message: ''
        }})
    }
    !notification.stats&&setTimeout(()=>dismiss(), 3000)
    
    return (
        <div 
            className={"bg-"+variant+" fixed-bottom py-2 px-3 drop-shadow d-flex justify-content-between align-items-center"}
            style={{bottom:'30px', right:'10px', left:'auto', maxWidth:'350px', minWidth: '250px', zIndex:1000000}}    
        >
            <p>{notification.stats??notification.message}</p>
            <FontAwesomeIcon
                className="p-0 m-0 pointer ms-4"
                onClick={()=>dismiss()}
                icon={faTimes}
            />
        </div>  
    )
}