import {
  Routes,
  Route,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { localDeployment } from "../../../API/config";
import InventoryAPI from "../../../API/inventory"

import { faBriefcase, faBook, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'

import LeftNav from "../../components/leftNav";
import AssignmentAPI from "../../../API/assignments";
import UploadsAPI from "../../../API/uploads";
import LoadResources from "./loadResources";
import { lazyLoad } from "../../components/Table/lazyLoad";

export default function Resources () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [groupResources, setGroupResources] = useState([])
    const [resources, setResources] = useState([])
    const [pageLoad, setPageLoad] = useState(1);
    const [prevInnerHeight, setPrevInnerHeight] = useState(0);
    const lazyloadContainer = useRef()

    const token = useSelector(state=>state.token);
    const role = useSelector(state=>state.role);
    const screen = useSelector(state=>state.screen);
    const tgtUser = useSelector(state=>state.tgtUser);
    const myGroup = useSelector(state=>state.myGroup);
    const myInfo = useSelector(state=>state.myInfo);
    const filters = useSelector(state=>state.filters).filter(item=>item.name==='resources')[0].value;
    const navigations = [
      {
        type:'Vendor',
        iconOnLeftNav: faCloudArrowUp,
        showOnLeftNav: myGroup._id==="1",
        homepage:false,

        resourcesIndex: UploadsAPI.vendorUploadsIndex,
        resourceAdd: async (data) => {
            return await UploadsAPI.uploadsPost(token, null, data)
        },
        actions:[
          {
          name:'Edit TOC',
          verb:'SHOW',
          execute: async (data)=>{
              await navigate('/epenApp/resourceIndexBySection')
              await dispatch({ type: 'SET_SCREEN', payload: 'vendor/resourceIndexBySection'})
              return await InventoryAPI.inventoryShow(token, data._id);
            }
          },
          {
          name:'Edit book data',
          verb:'PUT',
          execute: async (data)=>{
              return await UploadsAPI.updateUpload(token, data).then(()=>
                dispatch({ type: 'SET_NOTIFICATION', payload: {
                  show: true,
                  code: 200,
                  message: 'Resource updated.'
                }})
              );
          }
          },
          {
          name:'Remove',
          verb:'DELETE',
          disabled: ()=>!localDeployment,
          execute: async (data)=>{
              return await UploadsAPI.vendorUploadsRemove(token, data._id).then(()=>
                  dispatch({ type: 'SET_NOTIFICATION', payload: {
                    show: true,
                    code: 200,
                    message: 'Resource deleted.'
                  }})
              );
          }
          },
        ]
      },
      {
        type:'Inventory',
        iconOnLeftNav:faBook,
        homepage:(role==="admin"||myGroup._id==="1"),
        showOnLeftNav: true,
        
        resourcesIndex: InventoryAPI.inventoryIndex,
        groupResourcesIndex: InventoryAPI.groupInventoryIndex,
        actions:[
          {
          name:'Access',
          verb:'SHOW',
          execute: async (data)=>{
              await dispatch({ type: 'SET_SCREEN', payload: role==='admin'?'inventory/resourceIndexBySection':'inventory/editTool'})
              await navigate(role==='admin'?'/epenApp/resourceIndexBySection':'/epenApp/editTool')
              return await InventoryAPI.inventoryShow(token, data._id)
            }
          }
        ]
      },
      {
        type:'Assignment',
        iconOnLeftNav: faBriefcase,
        homepage:role!=="admin"&&myGroup._id!=="1",
        showOnLeftNav: !localDeployment,

        groupResourcesIndex: AssignmentAPI.assignmentsIndex,
        actions:[
          {
          name:'Access',
          verb:'SHOW',
          execute: async (data)=>{
            if(role==='user'||tgtUser._id) {
              await dispatch({ type: 'SET_SCREEN', payload: 'assignment/editTool'})
              await navigate('/epenApp/editTool')
            }
            return await AssignmentAPI.assignmentsShow(token, data._id);
          }
          },
          {
          name:'Revoke',
          verb:'DELETE',
          execute: async (data)=>{
              await AssignmentAPI.assignmentRemove(token, data._id);
          }
          }
        ],
      },
      // {
      //   type:'Upload',
      //   iconOnLeftNav: faUpload,
      //   showOnLeftNav: true,

      //   resourcesIndex: UploadsAPI.uploadsIndex,
      //   resourceAdd: async (data) => {
      //     await UploadsAPI.uploadsPost(token, null, data)
      //   },
      //   actions:[
      //     {
      //       name:'Access',
      //       verb:'SHOW',
      //       execute: async (data)=>{
      //         await dispatch({ type: 'SET_SCREEN', payload: 'uploads/resourceIndexBySection'})
      //         await navigate(role==="admin"?'/epenApp/resourceIndexBySection':'/epenApp/editTool')
      //         return await UploadsAPI.uploadsShow(token, data._id);
      //       }
      //     },
      //     {
      //       name:'Remove',
      //       verb:'DELETE',
      //       execute: async (data)=>{
      //           await UploadsAPI.uploadRemove(token, data._id).then(()=>
      //             dispatch({ type: 'SET_NOTIFICATION', payload: {
      //               show: true,
      //               code: 200,
      //               message: 'Resource deleted.'
      //             }})
      //         );
      //       }
      //     },
      //   ]
      // },
    ]
    useEffect(()=>{
      setGroupResources([]);
      setResources([]);
      
      screen.indexOf('editTool')<0&&dispatch({type:"RESET_RESOURCE"})
      screen.indexOf('assignment')<0&&dispatch({type:"RESET_USER"})
      dispatch({type:"RESET_BOOKMARK"})
      setPageLoad(1)
      setPrevInnerHeight(0)
    },[screen])

    useEffect(()=>{
      !(filters.input)&&setPageLoad(1)
    },[filters])
    return(
        <div 
          className="row m-0 p-0 h-100"
        >
          {(!localDeployment||myInfo.admin==="1")&&
          navigations.filter(navigation=>navigation.showOnLeftNav).some(item=>
            screen.indexOf(item.type.toLowerCase())>=0
          )&&role==="admin"&&
          <LeftNav
              navigations={navigations.filter(navigation=>
                navigation.showOnLeftNav
              )}
              screen={screen}
          />}
          <div 
            className="col m-0 overflow-y-scroll h-100 pt-4"
            onScroll={(e)=>{
                lazyLoad(e, setPageLoad, prevInnerHeight)&&setPrevInnerHeight(e.target.scrollHeight)
            }}
          >
            <Routes>
              {
                navigations.map((navigation, index)=>
                  <Route 
                    key={index} 
                    path={(navigation.homepage?"/":"/"+navigation.type.toLowerCase())} 
                    exact={navigation.homepage} 
                    element={
                      <LoadResources
                        lazyloadContainer={lazyloadContainer}
                        type={navigation.type.toLowerCase()}
                        pageLoad={pageLoad}
                        homepage={navigation.homepage}
                        groupResources={groupResources}
                        setGroupResources={setGroupResources}
                        resources={resources}
                        setResources={setResources}
                        
                        resourcesIndex={navigation.resourcesIndex}
                        groupResourcesIndex={navigation.groupResourcesIndex}
                        resourceAdd={navigation.resourceAdd}
                        actions={navigation.actions}
                      />
                    }/>
                )
              }
            </Routes>

          </div>
      </div>
    )
}