export default function Preload() {

    return (
        <div 
            className="position-fixed h-100 w-100 shadow-overlay d-flex justify-content-center align-items-center"
            style={{zIndex: 100000, top:0, left:0}}
        >
            <img src='/assets/preload.svg' alt=''/>
        </div>
    )
}