import TableOfContents from "../../screen/Resources/ResourceIndex/TableOfContents";
import LeftSlideToggleIcon from "../leftSlideToggleIcon";
import { useSelector } from 'react-redux';

export default function ResourcesNavigation () {

    const bookmark = useSelector(state=>state.bookmark)
    const currentResource = useSelector(state=>state.currentResource)

    return (
        <LeftSlideToggleIcon
            outline
            variant="secondary"
            label={currentResource.Chapters[bookmark.Chapter].Navigations[bookmark.Section].Label}
            slideInContent={()=>(
                <TableOfContents 
                    enableNavigate
                />
            )}
        />
    )

}