import axios from "axios";
import { api } from "./config";

export default class API{

    static async saveDrawing(token, resourceId, accountId, snapshot) {
        return axios.post(api+'api/navigator/' + resourceId +'/accounts/'+accountId+'/drawings', snapshot,
        {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async loadDrawing(token, resourceId, accountId) {
        return axios.get(api+'api/navigator/' + resourceId +'/accounts/' + accountId + '/drawings', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async updateDrawing(token, resourceId, accountId, drawingId, snapshot) {
        return axios.put(api+'api/navigator/' + resourceId +'/accounts/'+accountId+'/drawings/'+drawingId, snapshot,
        {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

}