

export default function NotificationBubble (props) {
    const {
        variant,
        children,
        className,
        style
    } = props

    return (
        <div 
            className={"bg-"+(variant?variant:"304d73")+" d-flex justify-content-center align-items-center rounded text-light ms-2 " +(className)}
            style={style}
        >
            {children}
        </div>
    )
}