import axios from "axios";
import { api } from "./config";

export default class API{
    static async getSource(token) {
        return axios.get(api+'api/payments',{
            'headers': {
                'Authorization': 'Bearer '+token
            }  
        })
    }

    static async bllingsIndex(token, filter, pagenumber) {
        return axios.get(api+'api/subscriptions?page='+pagenumber+
        '&category='+filter.category+
        '&subject='+filter.subject+
        '&level='+filter.level,{
            'headers': {
                'Authorization': 'Bearer '+token
            }      
        })
    }
    static async storeSubscripitons(token, data) {
        return axios.post(api+'api/subscriptions', data, 
        {'headers': {
            'Authorization': 'Bearer '+token
        }})
    } 

    static async updateSubscription(token, subscripitonId, id) {
        return axios.put(api+'api/subscriptions/'+subscripitonId, {data: id},{
            headers:{
                'Authorization': 'Bearer '+token
            }
        })
    }
}