import Worksheet from "../../../../components/Worksheet";

export default function ExpandedView(props) {
    const {
        worksheet,
        setShowExpanded,
        solution,
        category,
        navHeight
    } = props;
    return(
        <div
            className="position-fixed shadow-overlay w-100 overflow-y-scroll py-5 pointer"
            style={{zIndex:'1000000', height:'calc(100% - '+navHeight+')'}}
            onClick={()=>setShowExpanded(false)}
        >
            <div className="row align-items-center justify-content-center">
            <Worksheet
                className="w-50"
                category={category}
                src={worksheet}
            />
            {solution&&
            <Worksheet 
                className="col-6 my-5" 
                category={category}
                src={solution}
            />}
                
           </div>
        </div>
    )
}