import { useEffect } from "react";
import { useDispatch } from "react-redux";


export default function Setting (props) {
    const dispatch = useDispatch();

    return 
        <div>
            
        </div>
}