export const lazyLoad = (triggerElmt, triggerState, blockCondition) => {
    if(
        // block lazyload onpage change while pageload!==0
        (triggerElmt.target.scrollTop!==0&&
        // lazyload upon scoll hit bottom
        (triggerElmt.target.scrollHeight-triggerElmt.target.scrollTop)===triggerElmt.target.offsetHeight)&&
        // block lazy load when all contents has been loaded
        triggerElmt.target.scrollHeight!==blockCondition
    ){
        triggerState(state=>state+1)
        return true
    }else{
        return false
    }
}