import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { assetsSrc, localDeployment } from '../../../API/config';


export default function Worksheet (props) {

    const {
        src,
        className,
        category,
        onClick
    } = props
    
    const allowPrint = useSelector(state=>state.myInfo.allow_print)
    return (
        <div
            className={(className??undefined)+' position-relative overflow-hidden'}
            onClick={onClick}

        >
            {category==='Foundation'&&
            <Image
                className='position-absolute'
                style={{top:'5.5%', left:'5.5%', width:'88%'}}
                src={(allowPrint&&localDeployment)?'/assets/static-banner-local.jpg':'/assets/static-banner.jpeg'}
            />}
            <Image
                className='w-100 h-100'
                src={assetsSrc+src}
            />
        </div>
            
    )
}