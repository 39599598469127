import API from "../../../API/users";

export const loadUser = (token, srcGroup, filters, setEntries) => {
    API.userIndex(token, srcGroup, filters)
    .then(response=>{
        setEntries(response.data)
    })
    .catch(err=>console.log(err))
}
export const showUser = (token, data) => {
    return API.userShow(token, data)
}

export const addUser = (token, data) => {
    return API.addUser(token, data)
}

export const updateUser = (token, data) =>{
    return API.usersUpdate(token, data._id, data)
}

export const removeUser = (token, data) => {
    return API.removeUser(token, data._id)
}