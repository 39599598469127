import axios from "axios";
import { api } from "./config";

export default class API{

    static async uploadsPost(token, group, data) {

        return axios.post(api+'api/groups/'+group+'/uploads', data,{
            'headers': {
                'Authorization': 'Bearer '+token,
                "Content-Type": "multipart/form-data",
            }
        })
    }
    static async updateUpload(token, data) {
        return axios.post(api+'api/inventory/', data,{
            'headers': {
                'Authorization': 'Bearer '+token,
                "Content-Type": "multipart/form-data",
            }
        })
    }
    static async uploadsIndex(token, group, user, pagenumber) {
        return !user?
        axios.get(api+'api/groups/'+group+'/uploads?page='+pagenumber, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
        :
        axios.get(api+'api/groups/'+group+'/user/'+user+'/uploads?page'+pagenumber,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async vendorUploadsIndex(token, filterUpdate, pagenumber) {
        return axios.get(api+'api/uploads?page='+pagenumber+
        '&category='+filterUpdate.category+
        '&subject='+filterUpdate.subject+
        '&level='+filterUpdate.level,{
            'headers': {
                'Authorization': 'Bearer '+token
            }      
        })
    }

    static async uploadsShow(token, id) {
        return axios.get(api+'api/uploads/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async uploadsRemove(token, id) {
        axios.delete(api+'api/uploads/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async vendorUploadsRemove(token, id) {
        axios.delete(api+'api/inventory/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
}