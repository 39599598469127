import GroupsAPI from '../../../API/groups';

export const loadGroups = (token, srcGroupId, filters, setEntries) => {
    GroupsAPI.groupsShow(token, srcGroupId, filters)
    .then(response=>{
        setEntries(response.data)
    })
    .catch(err=>console.log(err))
}
export const showGroup = (token, data) => {
    return GroupsAPI.groupsShow(token, data)
}

export const addGroup = (token, data) => {
    return GroupsAPI.addGroup(token, data)
}

export const updateGroup = (token, data) => {
    return GroupsAPI.updateGroup(token, data)
}

export const removeGroup = (token, data) => {
    return GroupsAPI.removeGroup(token, data._id)
}