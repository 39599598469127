import React, {useRef, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import Bodytype from './bodytype';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Button, Carousel } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { localDeployment } from '../../API/config';
import { useNavigate } from 'react-router-dom';

function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const headerSlide = useRef(null)

    const sildeInfo = [
        {
            title: 'Real Time Share Editing',
            desc: 'Students and instructors can work together online as if they’re in the same room.',
            imgSrc:'./assets/realtime.gif',
            className:'float-left d-block',
            imgStyle:{width: isMobile?'100%':'60%', marginTop:'10%'},
            
            isBtnAvail : true,
            btnTo:'RealTimeEditing',
            btnLabel:'Learn More',

            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
        {
            title: 'Instant Detailed Solution',
            desc: 'with a left-swipe',
            imgSrc:'./assets/solution-swipe.gif',
            className:'float-left d-block',
            imgStyle:{width:isMobile?'100%':'80%', marginLeft: isMobile?'0':'-10%', marginTop:'4%'},


            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
        {
            title: 'Make a Note',
            desc: 'Swipe right. Show your thought process so your instructor can share his or hers. ',
            imgSrc:'./assets/note-swipe-2.gif',
            className:'float-left d-block',
            imgStyle:{width:isMobile?'100%':'60%', marginTop:'10%'},


            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
        {
            title: 'Resource Management System',
            desc: 'Assign - Save with different devices',
            bgVideo: true,
            imgSrc:'./assets/manage.mp4',
            className:'float-left d-block',
            imgStyle:{width:isMobile?'100%':'60%', marginTop:'10%'},

            isBtnAvail : true,
            btnTo:'ResourceManagement',
            btnLabel:'Learn More',

            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
        // {
        //     title: 'Auto Grading',
        //     desc: 'with a simple click',
        //     bgVideo: true,
        //     imgSrc:'./assets/autoGrading.mp4',
        //     className:'float-left d-block',
        //     imgStyle:{width:isMobile?'100%':'60%', marginTop:'10%'},

        //     isLinkAvail : true,
        //     linkTitle:'Free signup',
        //     linkUrl: '/epenApp',
        // },
        {
            title: 'Teacher Select - Student Pay',
            desc: '',
            imgSrc:'./assets/browse.png',
            className:'float-left d-block',
            imgStyle:{width:isMobile?'100%':'60%', marginTop:'10%'},

            isBtnAvail : true,
            isBtntoUrl : true,
            btnTo:'./epenstore',
            btnLabel:'Browse Resources',

            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
        {
            title: 'Premium Editing Tools That Work Across Different Devices',
            desc: 'Check in with your instructor with ease.',
            imgSrc:'./assets/responsive.png',
            className:'float-left d-block',
            imgStyle:{width:isMobile?'100%':'60%', marginTop:'10%'},

            isBtnAvail : false,

            isLinkAvail : true,
            linkTitle:'Free signup',
            linkUrl: '/epenApp',
        },
    ]

    async function scrollFurther(e) {
        $('#'+e+' button').click();

        await setTimeout(() => {
            window.scrollTo({
                top: headerSlide.current.offsetHeight,
                behavior: 'smooth'
                });
        },100)
    }

    const scrollToTop = () =>{
        window.scrollTo({
        top: 0,
        behavior: 'smooth'
        });
        collapse()
    };
    const collapse = () => {
        const parent = $('.accordion-collapse.collapse.show').parent().children()[0]
        if(parent){
            $('#'+parent.id+' button').click();
        }
    };
        
    $('.carousel .control-dots').click(
        scrollToTop
    )
    useEffect(()=>{
        dispatch({type:'SET_PORTAL', payload:'EpenStore'})
        localDeployment&&navigate("./epenApp/")
      },[dispatch])
    return(
    <div>
        <div ref={headerSlide} className={``} style={{marginTop:isMobile?'5%':'0'}}>
            <Carousel
                autoPlay
                className={``}
                controls={false}
            >
            {
                sildeInfo.map((content, index) =>
                <Carousel.Item interval={8000} key={index} className='resize-carousel'>
                        <div className={`${isMobile? 'h-50 d-flex justify-content-center align-items-center overflow-hidden':''} `}>
                            {content.bgVideo? 
                            <video playsInline muted autoPlay loop type='video/mp4' className={content.className} style={content.imgStyle}>
                                <source src={content.imgSrc} />
                            </video>:
                            <img
                                className={content.className}
                                style={content.imgStyle}
                                src={content.imgSrc}
                                alt=""
                            />
                            }
                        </div>
                    <Carousel.Caption 
                        className={`${isMobile? 'align-items-end':'align-items-center'} h-100 w-100 m-0 d-flex p-0`} 
                        style={{left:0, top:0, bottom:0, right:0}}
                    >
                        <div 
                            className={`${isMobile? 'h-50 pb-5 px-2 mx-auto':'text-left px-5 h-100 ms-auto'} col-lg-5 col-md-12 text-304d73 bg-white d-flex flex-column justify-content-center`}>
                            <h2 className=''>{content.title}</h2>
                            <p className='f-14pt'>{content.desc}</p>
                            <div className={`${isMobile? '':'d-flex'}  mt-3 seccond-font`}>
                                {content.isBtnAvail && !content.isBtntoUrl&&
                                <Button 
                                    onClick={()=>scrollFurther(content.btnTo)} 
                                    variant='outline-dark'
                                    className={`${isMobile? '':''} me-1 mb-1`}>
                                        {content.btnLabel}
                                </Button>
                                }
                                {content.isBtnAvail && content.isBtntoUrl&&
                                <Link to={content.btnTo}>
                                    <Button 
                                        variant='outline-dark'
                                        className='mx-1 mb-1'>
                                            {content.btnLabel}
                                    </Button>
                                </Link>
                                }
                                {content.isLinkAvail &&
                                <Link 
                                    to={content.linkUrl}
                                    onClick={()=>
                                        dispatch({type:'SET_SCREEN', payload:'register'})
                                    }
                                >
                                    <Button 
                                        variant='outline-dark'
                                        className='bg-304d73 text-white border-0 mx-1 mb-1'>
                                            {content.linkTitle}
                                    </Button>
                                </Link>
                                }
                                {content.isLink2Avail && 
                                    <Button className='bg-304d73 text-white border-0 mb-1'
                                        onClick={()=>scrollFurther(content.linkUrl2)}
                                    >{content.linkTitle2}
                                    </Button>
                                }
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                )
            }
            </Carousel>
        </div>
        <Bodytype 
            style={{backgroundColor:'#fff'}}
        />
            
    </div>
    )
}

export default Home