import { Button, Dropdown } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faInfo } from '@fortawesome/free-solid-svg-icons'
import NotificationBubble from '../../../../components/notificationBubble';


export default function FilterContainer (props) {
    const {
        filters,
        children
    } = props
    const dispatch = useDispatch();
    return (
        <Dropdown
            className='p-0 position-relative'
            autoClose="outside"
        >
            <Dropdown.Toggle 
                className='border-0 bg-none d-flex align-items-center lighter-hover text-secondary' size="sm"
            >
                    <FontAwesomeIcon
                        icon={faFilter}
                    />
                    {filters.filter(item=>item.name==='resources').map(items=>
                        items.value.category.length+
                        Number(items.value.level)+
                        items.value.subject.length
                    )[0]>2&&
                    <NotificationBubble 
                        className="px-2 py-1 mx-1"
                    >
                        <FontAwesomeIcon
                            style={{fontSize:"12px"}}
                            icon={faInfo}
                        />
                    </NotificationBubble>
                    }
            </Dropdown.Toggle>

            <Dropdown.Menu 
                className='drop-shadow'
                style={{zIndex: 1040}}
            >
            <div 
                className='d-flex mx-2 secondary-font'
            >
                {children}
                <Button
                    className='text-dark bg-none border-0 lighter-hover'
                    onClick={()=>
                        dispatch({type:'RESET_FILTERS'})
                    }
                >
                    Clear
                </Button>
            </div>
            </Dropdown.Menu>
        </Dropdown>
    )

}