import { Button, Form } from "react-bootstrap";
import LazyLoadList from "../Table/lazyLoadList";
import Searchbar from "../SubNav/searchbar";
import Table from "../Table";
import { useState } from "react";
import UserAPI from "../../../API/users";
import { useSelector } from "react-redux";

export default function AssignToUsers (props) {
    const {
        setAssignmentModel,
        sendToStudents
    } = props;
    const entryTemplate =
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Full name",
            key:'fullname',
            col:'',
        },
        // {
        //     label:"Solution",
        //     key:'conditionalComponent',
        //     col:'3',
        //     conditionalComponent: ()=>
        //     <Form className="d-flex justify-content-center align-items-center h-100">
        //         <Form.Check
        //             type="switch"
        //         />
        //     </Form>
        // }
    ];
    const [users, setUsers] = useState([]);
    const [studentsToAssign, setStudentsToAssign] = useState([])
    return (
        <div
            className="shadow-overlay fixed-top d-flex align-items-center justify-content-center drop-shadow"
            style={{zIndex:1000000}}
        >
            <div
                className="bg-white rounded row align-items-between p-2"
                style={{width:'500px', height:'600px'}}
            >
                <div className="py-3 px-4">
                    <Searchbar
                        type={'users'}
                    />
                </div>

                <div className="w-100 h-75">
                <Table
                    tableMenuActions={[]}
                    tableEntryTemplates={entryTemplate}
                    style={{width:'100%', height:'100%'}}
                >
                    <div 
                        className="w-100 row px-3 py-2 align-items-center"
                    >
                        <div className="col-auto">
                            <Form.Check
                                type='checkbox'
                                checked={users.length===studentsToAssign.length}
                                onChange={()=>
                                    users.length!==studentsToAssign.length?
                                    setStudentsToAssign(users.map(user=>user._id))
                                    :
                                    setStudentsToAssign([])
                                }
                            />
                        </div>
                        <div className="col text-dark">
                            <b>All</b>
                        </div>
                    </div>
                    <LazyLoadList
                        tableEntryTemplates={entryTemplate}
                        entries={[]}
                        groupEntries={users}
                        setGroupEntries={setUsers}
                        isChecked={(e)=>studentsToAssign.some(item=>item===e)}
                        groupEntriesIndex={UserAPI.userIndex}
                        onEntryCheck={(e)=>studentsToAssign.some(item=>item===e)?
                            setStudentsToAssign(studentsToAssign.filter(item=>item!==e))
                            :
                            setStudentsToAssign([...studentsToAssign, e])
                        }
                        extractFilter={(e)=>e.filter(item=>item.name==='users')[0].value}
                        className={"text-dark"}
                    />
                </Table>
                </div>
                <div className="d-flex align-items-end justify-content-around pt-4 pb-2">
                    <div>
                        <Button
                            variant="danger"
                            onClick={()=>setAssignmentModel(false)}    
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button 
                            variant="primary"
                            disabled={studentsToAssign.length===0}
                            onClick={()=>sendToStudents(studentsToAssign)}
                        >
                            Send
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    )
}
