const InitialState = {

    showPreload:false,
    showLeftSlide:false,
    showRightSlide:false,
    showBottomTray:false,
    leftSlideContent:'',
    showNav:false,
    currentProgress:[],
    notification: {
        show:false,
        code: '',
        message: ''
    },

    //account holder info
    token:'',
    myInfo:'',
    role:'',
    myGroup:'',

    //path info
    screen:'',
    portal:'EpenStore',

    //group management
    srcGroup:{_id:"", name:""},
    
    // resources managment

    // search and filter
    keywords:'',


}
const updatableBookmark = {
    bookmark:{"Chapter":0,"Section":0,"label":""},
}
const resetCart = {
    cart:[],
}
const resetFilters = {
    filters:[
        {name:'resources', value: {category: '0', subject:'0', level:'0', input:''}},
        {name:'groups', value: {input:''}},
        {name:'users', value: {role:'all' , input:''}}
    ],
}
const updatableVar = {
    allowSubmit: false,
}
const udatableGroups = {
    tgtGroup: {
        "name": "",
        "details": {
          "address": null,
          "contact": [
            {
              "name": null,
              "email": null,
              "phone": null
            }
          ]
        },
        "users": [],
        "admins": [],
        "parent_groups": '',
        "sync_with_root": "false",
        "children_groups": [],
        "status": 1,
        "allow_solution": [],
        "inventory_items": []
    },
}
const udatableUsers = {
    tgtUser: {
        "groups": [],
        "firstname": "",
        "lastname": "",
        "username": "",
        "allow_print": false,
        "admin": "",
    }
}
const udatableResources = {
    currentResource: {
        "Title":"",
        "Category": "Concept",
        "Subject": "Math",
        "Level": "13",
        "Workbook": "",
        "Cover":"",
        "Solutions":[],
        
        "priceMonthly":"",
        "priceYearly":"",
        "internalUse":"true",

        "portal":"vendor"
    },

}
const rootReducer = (state = {
    ...InitialState,
    ...updatableVar,
    ...udatableResources,
    ...udatableUsers,
    ...udatableGroups,
    ...resetFilters,
    ...resetCart,
    ...updatableBookmark
}, action) => {
  switch (action.type) {
    case'SHOW_PRELOAD':
        return { ...state, showPreload: action.payload };
    case'SHOW_LEFT_SLIDE':
        return { ...state, showLeftSlide: action.payload };
    case'LEFT_SLIDE_CONTENT':
        return { ...state, leftSlideContent: action.payload };
    case'SHOW_RIGHT_SLIDE':
        return { ...state, showRightSlide: action.payload };
    case'SHOW_BOTTOM_TRAY':
        return { ...state, showBottomTray: action.payload };
    case 'UPDATE_ENTRIES':
        return {...state, updateEntries: action.payload };
    case 'SET_ALLOW_SUBMIT':
        return {...state, allowSubmit: action.payload };
    case 'SET_NAV':
        return { ...state, showNav: action.payload };
    case 'SET_CURRENT_PROGRESS':
        return { ...state, currentProgress: action.payload };
    case 'SET_NOTIFICATION':
        return { ...state, notification: action.payload };

    //account holder data
    case 'SET_TOKEN':
        return { ...state, token: action.payload };
    case 'SET_MY_INFO':
        return { ...state, myInfo: action.payload };
    case 'SET_ROLE':
        return { ...state, role: action.payload };
    case 'SET_MY_GROUP':
        return { ...state, myGroup: action.payload };

    //group managment
    case 'SET_SRC_GROUP':
        return { ...state, srcGroup: action.payload };
    case 'SET_TGT_GROUP':
        return { ...state, tgtGroup: action.payload };

    //user management
    case 'SET_TGT_USER':
        return { ...state, tgtUser: action.payload };

    //resources management
    case 'SET_BOOKMARK':
        return { ...state, bookmark: action.payload };
    case 'SET_CURRENT_RESOURCE':
        return { ...state, currentResource: action.payload };
    case 'SET_CART':
        return { ...state, cart: action.payload };

    //path location
    case 'SET_SCREEN':
        return { ...state, screen: action.payload };
    case 'SET_PORTAL':
        return { ...state, portal: action.payload };

    //search and filter
    case 'SET_KEYWORDS':
        return { ...state, keywords: action.payload };
    case 'SET_FILTERS':
        return {...state, filters: action.payload };
        

    case 'LOG_OUT':
        return {
            ...state,
            ...InitialState,
            ...updatableVar,
            ...udatableResources,
            ...udatableUsers,
            ...udatableGroups,
            ...resetFilters,
            ...updatableBookmark
        };
    case 'RESET_ALL_UPDATABLES':
        return {
            ...state,
            ...updatableVar,
            ...udatableResources,
            ...udatableUsers,
            ...udatableGroups,
            ...updatableBookmark,
            ...resetFilters,
        };
    case 'RESET_FILTERS':
        return {
            ...state,
            ...resetFilters
        };
    case 'RESET_RESOURCE':
        return {
            ...state,
            ...udatableResources
        };
    case 'RESET_BOOKMARK':
        return {
            ...state,
            ...updatableBookmark
        };
    case 'RESET_USER':
        return {
            ...state,
            ...udatableUsers
        };
    case 'RESET_GROUP':
        return {
            ...state,
            ...udatableGroups
        };
    case 'CLEAR_CART':
        return {
            ...state,
            ...resetCart
        }
    default:
        return state;
  }
};

export default rootReducer;