import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../components/Table";
import BillingsAPI from "../../../API/payments";
import RowContainer from "../../components/Table/rowContainer";
import PaymentAPI from "../../../API/payments";


export default function Subscription (props) {

    const {
        screen,
    } = props;
    const token = useSelector(state=>state.token)
    const dispatch = useDispatch();
    const [billings, setBillings] = useState();
    const [pageLoad, setPageLoad] = useState(1);
    const tableEntryTemplates =
    [
        {
            label:"",
            key:'',
            col:'auto',
        },
        {
            label:"Book Title",
            key:'Title',
            col:'',
        },
        {
            label:"Subject",
            key:'Subject',
            col:'3',
        },
        {
            label:"Level",
            key:'Level',
            col:'2',
        },
        {
            label:"Interval",
            key:'interval',
            col:'2',
        },
        {
            label:"Price",
            key:'price',
            col:'2',
        }
    ]
    const updateBillings = (item) => {
        PaymentAPI.updateSubscription(token, item.subscriptionID, item.currentResource)
        .then(async ()=>{
            await dispatch({ type: 'UPDATE_ENTRIES', payload: true})
        })
        .catch(err=>console.log(err))
        dispatch({ type: 'UPDATE_ENTRIES', payload: false})
    }
    return(
    <div className="h-100 d-flex align-items-center justify-content-center">
        <Table
            className="drop-shadow"
            tableEntryTemplates={tableEntryTemplates}
            screen={screen}
        >
            {/* {personalBillings.map((recurrence, index)=>
                <SubscriptionTable
                    key={index}
                    recurrenceObject={recurrence}
                    updateBillings={updateBillings}
                />
            )} */}
                <RowContainer
                    target={'resources'}
                    tableEntryTemplates={tableEntryTemplates}
                    extractFilter={(e)=>e.filter(item=>item.name==='resources')[0].value}
                    onLoad={(setEntries, filters)=>BillingsAPI.bllingsIndex(token, filters, 1).then(res=>setEntries(res))}
                />
        </Table>
    </div>
    )
}