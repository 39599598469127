import { Button } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Worksheet from "../../../components/Worksheet";
import {useSelector } from "react-redux";


export default function ResourceIndexBySection(props) {
    const {
        style,
        setWorksheets,
        worksheets,
        worksheetsIndex,
        removeWorksheets,
        isUploadResources,
        setShowExpanded,
    } = props
    const previewHeight = 'calc(100vh - '+style.paddingBottom+')';
    const currentResource = useSelector(state=>state.currentResource)
    const [showPreviewAction, setShowPreviewAction] = useState(false);
    const [zoomInView, setZoomInView] = useState()

    return(
        <div
            className="position-fixed row bg-secondary"
            style={{
                zIndex:isUploadResources?'1000':'10000',
                right:isUploadResources?'auto':0, 
                width:isUploadResources?'100%':'71.5%', 
                height:style.height?style.height:'100%'
            }}
        >
            <div 
                className="pt-2 col-xl-5 col-md-6 overflow-y-scroll scrollbar-hide h-100"
            >
            <div className="row m-1 pb-2">
                <div className="col-auto">
                    <Button 
                        variant='outline-light'
                        className=""
                        onClick={()=>{
                            !worksheets.some(x=>worksheetsIndex.includes(x))?
                            setWorksheets(worksheets=>[...worksheets, ...worksheetsIndex])
                            :
                            removeWorksheets('all')
                        }}
                    >
                        {!worksheets.some(x=>worksheetsIndex.includes(x))?'Select All':'Deselect All'}
                    </Button>
                </div>
            </div>
            <div className="row">
            {worksheetsIndex.map((items, index)=>
                <div
                    key={index}
                    className="col-auto mb-3 mx-1 p-0 drop-shadow position-relative" 
                    style={{
                        height: 220, 
                        width: 170
                    }}
                >
                    {worksheets.some(x => items === x)&&
                        <div
                            className="w-100 h-100 position-absolute justify-content-center align-items-center d-flex pointer"
                            style={{backgroundColor:'rgba(0,0,0,0.5)', zIndex:1000}}
                            onClick={()=>removeWorksheets(items)}
                        >
                            <FontAwesomeIcon 
                                className='text-white font-icons-large'
                                icon={faCheckCircle}
                            />
                        </div>
                    }
                    <Worksheet
                        src={currentResource.type==='Uploads'?'users-upload/':'vendors-upload/'+currentResource._id+"/"+items+"?w=150"}
                        className='h-100 w-100 pointer lighter-hover'
                        category={currentResource.Category}
                        onClick={()=>{
                            setWorksheets(item=>[...item, items])
                            setZoomInView(items)
                        }}
                    />
                </div>
            )}
            </div>
            </div>
            <div
                className="col pt-5"
                style={{height: previewHeight}}
                onMouseEnter={()=> setShowPreviewAction(true)}
                onMouseLeave={() => setShowPreviewAction(false)}
            >
                {zoomInView&&
                <div
                    className="position-relative pointer col-11 mx-auto"
                    onClick={()=>setShowExpanded(zoomInView)}
                >
                    {showPreviewAction&&<div
                        className="h-100 w-100 position-absolute justify-content-center align-items-center d-flex pointer"
                        style={{backgroundColor:'rgba(0,0,0,0.5)', zIndex: 10000}}
                    >
                        <FontAwesomeIcon 
                            className='text-white font-icons-large'
                            icon={faMagnifyingGlass}
                        />
                    </div>}
                    <Worksheet
                        className="drop-shadow"
                        category={currentResource.Category}
                        src={currentResource.type==='Uploads'?'users-upload/':'vendors-upload/'+currentResource._id+"/"+zoomInView}
                    />
                </div>}
            </div>

        </div>
    )
}