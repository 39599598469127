import { isMobile, isTablet } from "react-device-detect"
import { Image } from "react-bootstrap"


function Footer() {
    return(
        <div id='contact' className='bg-304d73 text-white position-relative' style={{zIndex:100, height:isMobile?'auto':'75vh'}}>
            <div className='h-100'>
                <div className="h-75">
                    <div className="row m-0 h-100">
                        <div className="col-lg-4 p-0 overflow-hidden d-flex align-items-center justify-content-center h-50 p-3 border-custom">
                            <Image className="w-100" src="./assets/student holding books.png"/>
                        </div>
                        <h2 className="col-lg-8 mx-auto d-flex align-items-center">Ask WHY more and more students are using digital workbooks with Epenclass every day.</h2>
                        <div className="col-lg-4 p-0 overflow-hidden d-flex align-items-center justify-content-center h-50 p-3 border-custom">
                            <Image className="w-100" src="./assets/carryingbooks_backpack.jpg"/>
                        </div>
                        <div className="col-lg-4 p-0 overflow-hidden d-flex align-items-center justify-content-center h-50 p-3 border-custom">
                            <Image className="w-100" src="./assets/College student_using ipad.jpg"/>
                        </div>
                        <div className="col-lg-4 p-0 overflow-hidden d-flex align-items-center justify-content-center h-50 p-3 border-custom">
                            <Image className="w-100" src="./assets/Ipad_back_pack.png"/>
                        </div>
                    </div>
                </div>
                <div  className="h-25">
                    <div className={`shadow ${isMobile&&!isTablet? 'py-5 mx-2':'py-4 mx-auto'}`} style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                        <ul className="p-0 pt-1 text-left w-100 row m-0 text-center align-items-center">
                            <li className="col-lg-4"><h5 className="m-0">Email: admin@epenclass.com</h5></li><br/>
                            <li className="col-lg-4"><h5 className="m-0">English / Tiếng Việt: (604) 707-0357</h5></li>
                            <li className="col-lg-4"><h5 className="m-0">中文: (604) 707-0398</h5></li>
                        </ul>
                    </div>
                    <div id="footer"><p className="text-center m-0 py-3 px-1">© {new Date().getFullYear()} EpenClass. Powered by <b>DataPi Learning Inc</b>. All right Reserved.</p></div>
                </div>
            </div>
        </div>
    )
}

export default Footer